import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import DataTable from "../components/DataTable";
import Authenticated from "../components/Authenticated";
import {
  addExpense,
  selectExpenseList,
  setExpenseList,
} from "../features/expense/expenseSlice";

export default function Expenses() {
  const Component = () => {
    return (
      <div>
        <ExpenseEntry />
        <ExpenseList />
      </div>
    );
  };
  return (
    <Authenticated
      component={Component}
      msg="Please login before using Expenses"
      redirect="/expenses"
    />
  );
}

export function ExpenseEntry() {
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData(e.target);
    fetch("/api/expenses/add_expense", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => dispatch(addExpense(data)));
  };

  return (
    <Container>
      <h2>Expense entry</h2>
      <Form onSubmit={(e) => onSubmit(e)}>
        <FormGroup>
          <Label for="type">Type</Label>
          <Input type="select" name="type" id="type">
            <option value="" defaultValue>
              ------------
            </option>
            <option value="Food">Food</option>
            <option value="Goods">Goods</option>
            <option value="Service">Service</option>
            <option value="Room">Room</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Travel">Travel</option>
            <option value="Deduction">Deduction</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="desc">Desc</Label>
          <Input type="text" name="desc" id="desc" placeholder="desc" />
        </FormGroup>
        <FormGroup>
          <Label for="cost">Cost</Label>
          <Input type="number" name="cost" id="cost" placeholder="cost" />
        </FormGroup>
        <FormGroup>
          <Label for="notes">Notes</Label>
          <Input type="text" name="notes" id="notes" placeholder="notes" />
        </FormGroup>
        <Button color="primary">Submit</Button>
      </Form>
    </Container>
  );
}

export function ExpenseList() {
  const expenseList = useSelector(selectExpenseList);
  // const dispatch = useDispatch();
  //Add the rest of the logic here to render the list of expenses

  const dispatch = useDispatch();
  const getData = () => {
    if (expenseList.length === 0) {
      fetch("/api/expenses/list_expenses")
        .then((response) => response.json())
        .then((data) => dispatch(setExpenseList(data)));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function onDelete(id) {
    var i;
    var data = [...expenseList];
    for (i = 0; i < data.length; i++) {
      if (id === data[i]["id"]) {
        data.splice(i, 1);
        const formData = new FormData();
        formData.append("id", id);
        fetch("/api/expenses/delete_expense", {
          method: "POST",
          body: formData,
        });
      }
    }
    dispatch(setExpenseList(data));
  }
  return (
    <Container className="mt-5">
      <h2>Expense list</h2>
      {expenseList.length > 0 && (
        <DataTable data={expenseList} onDelete={onDelete} />
      )}
    </Container>
  );
}
