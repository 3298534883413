import React, { useState } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Alert, Container } from "reactstrap";
import DynamicForm from "../components/FormGenerator";
import { setLogin } from "../features/auth/authSlice";

function Login(props) {
  const state = props.location.state || {};
  const { redirect = undefined, msg } = state;
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    console.log(data);
    if (data.ok) {
      dispatch(
        setLogin({
          username: Cookies.get("username"),
          admin: Cookies.get("admin"),
        })
      );
      props.history.push(redirect || "/");
    }
  };
  const checkLoggedin = () => {
    fetch("api/auth/login")
      .then((response) => response.json())
      .then((data) => {
        if (data.logged_in) {
          dispatch(setLogin(Cookies.get("username")));
        }
      });
  };
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const url = "api/auth/login";
  const fields = [
    { type: "FormEmail", name: "email", label: "Email" },
    { type: "FormPassword", name: "password", label: "Password" },
  ];
  console.log(props.location);
  return (
    <Container>
      {msg && (
        <Alert color="warning" isOpen={visible} toggle={onDismiss}>
          {msg}
        </Alert>
      )}
      <h2>Login</h2>
      <DynamicForm fields={fields} url={url} onSubmit={onSubmit} />
    </Container>
  );
}

export default Login;
