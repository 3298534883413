import React from "react";


export default function () {
  return (
    <div>
      <h2>Welcome to Octobot</h2>
      <p>
        This app is a combination of several applications. Use the navigation at
        the top to find the application you are looking for.
      </p>
    </div>
  );
}
