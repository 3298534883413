import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Container } from "reactstrap";
import DynamicForm from "../components/FormGenerator";

// function onSubmit(e) {
//   e.preventDefault();
//   var formData = new FormData(e.target);
//   console.log(formData);
//   console.log(e.target);
//   console.log(e.target.name.value);
//   console.log(e.target.mceComment.value);
//   console.log(e.target.mceComment2.value);

//   // for (var key in formData.keys()) {
//   //   console.log(key);
//   // }
//   // for (var value in formData.values()) {
//   //   console.log(value);
//   // }
// }

function FormElements() {
  const url = "api/core/post";
  const onSubmit = (json) => console.log(json);

  const options = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
  ];
  const fields = [
    { type: "FormText", name: "comment", label: "Comment" },
    { type: "FormEmail", name: "email", label: "Email" },
    { type: "FormDate", name: "birthday", label: "Birthday" },
    { type: "FormSelect", name: "options", label: "Options", options },
    { type: "FormTinyMce", name: "essay", label: "Essay" },
  ];
  return (
    <Container>
      <h2>Comment Box</h2>
      <DynamicForm fields={fields} url={url} onSubmit={onSubmit} />
    </Container>
  );
}

export default FormElements;
