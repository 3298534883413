import React from "react";
import { Route, Switch } from "react-router-dom";
import Person from "../features/person/Person";
import About from "../pages/About";
import Expenses from "../pages/Expenses";
import Comment from "../pages/Comment";
import CsrfLogin from "../pages/Csrf";
import CsrfLoginOnSubmit from "../pages/CsrfOnSubmit";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Pricing from "../pages/Pricing";
import Register from "../pages/Register";
import Repl from "../pages/Repl";
import SignUp from "../pages/SignUp";
import Tables from "../pages/Tables";
import FormElements from "../pages/FormElements";
import FormGenerator from "../pages/FormGenerator";
import CreatePost from "../features/blog/CreatePost";
import UpdatePost from "../features/blog/UpdatePost";
import ListPosts from "../features/blog/ListPosts";
import ViewPost from "../features/blog/ViewPost";
import ViewPosts from "../features/blog/ViewPosts";

export default function () {
  return (
    <Switch>
      <Route path="/expenses" render={(props) => <Expenses {...props} />} />
      <Route path="/blog/createPost" exact render={(props) => <CreatePost {...props} />} />
      <Route path="/blog/updatePost/:title" render={(props) => <UpdatePost {...props} />} />
      <Route path="/blog/listPosts" exact render={(props) => <ListPosts {...props} />} />
      <Route path="/blog/:title" render={(props) => <ViewPost {...props} />} />
      <Route path="/blog" exact render={(props) => <ViewPosts {...props} />} />
      <Route path="/about" render={(props) => <About {...props} />} />
      <Route path="/dashboard" render={(props) => <Dashboard {...props} />} />
      <Route path="/register" render={(props) => <Register {...props} />} />
      <Route path="/comment" render={(props) => <Comment {...props} />} />
      <Route
        path="/formElements"
        render={(props) => <FormElements {...props} />}
      />
      <Route
        path="/formGenerator"
        render={(props) => <FormGenerator {...props} />}
      />
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route path="/Tables" render={(props) => <Tables {...props} />} />
      <Route path="/pricing" render={(props) => <Pricing {...props} />} />
      <Route path="/signup" render={(props) => <SignUp {...props} />} />
      <Route path="/repl" render={(props) => <Repl {...props} />} />
      <Route path="/person" render={(props) => <Person {...props} />} />
      <Route
        path="/csrfLogin"
        exact
        render={(props) => <CsrfLogin {...props} />}
      />
      <Route
        path="/csrfLoginOnSubmit"
        exact
        render={(props) => <CsrfLoginOnSubmit {...props} />}
      />
      <Route path="/" exact render={(props) => <Home {...props} />} />
    </Switch>
  );
}
