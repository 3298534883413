import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Container, Form } from "reactstrap";
import {
  FormEmail,
  FormText,
  FormSelect,
  FormDate,
  FormTextArea,
  FormTinyMce,
  FormSelectMulti,
  FormRadio,
  FormCheck,
} from "../components/FormElements";

function onSubmit(e) {
  e.preventDefault();
  var formData = new FormData(e.target);
  console.log(formData);
  console.log(e.target);
  console.log(e.target.name.value);
  console.log(e.target.mceComment.value);

  // for (var key in formData.keys()) {
  //   console.log(key);
  // }
  // for (var value in formData.values()) {
  //   console.log(value);
  // }
}

function FormElements() {

  const options = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
  ];
  return (
    <Container>
      <h2>Comment Box</h2>
      <Form onSubmit={(e) => onSubmit(e)}>
        <FormEmail name="email" label="Email" />
        <FormText name="name" label="Name" />
        <FormSelect name="select" label="Select" options={options} />
        <FormSelectMulti
          name="select multi"
          label="Select Multi"
          options={options}
        />
        <FormDate name="date" label="Date" />
        <FormRadio name="radio" label="Radio" options={options} />
        <FormCheck name="check" label="Check" />
        <FormCheck name="check2" label="Check2" />
        <FormTextArea name="comment" label="Comment" />
        <FormTinyMce name="mceComment" label="MCE Comment" />
        <Button color="primary">Submit</Button>
      </Form>
    </Container>
  );
}

export default FormElements;
